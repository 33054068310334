<template>
  <router-view />
</template>

<style>
/* @import url("bulma/css/bulma.min.css"); */
@import url("./assets/css/contacto.css");
@import url("./assets/css/experiencia.css");
@import url("./assets/css/index.css");
@import url("./assets/css/main.css");
@import url("./assets/css/organizacion.css");
@import url("./assets/css/servicios.css");
@import url("./assets/css/vacantes.css");
</style>
